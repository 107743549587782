"use client"

import React, { useEffect, useRef } from 'react';
import { getMediaFileUrl } from '../pages/Home';

const Gallery = ({ images }) => {
    const galleryRef = useRef(null);

    return (
        <div className="container mx-auto mb-8 mt-4">
            <h1 className='text-2xl font-bold text-center border-b-2 pb-2 max-w-[400px] mx-auto border-[#2D408F] text-[#2D408F]'>Templates</h1>
            <div className="flex flex-col sm:flex-row flex-wrap justify-center items-center sm:my-[100px]">
                {images && images?.map((image, index) => {
                    // for even items transform top 100 otherwise bottom 100
                    const transform = index % 2 === 0 ? 'sm:-translate-y-[40px]' : 'sm:translate-y-[40px]';

                    return <div className={`shadow-xl bg-red-400 border w-[95%] sm:w-[22%] mx-2 h-[250px] transform ${transform} gallery-item rounded-[8px] overflow-hidden`}>
                        <img key={`clone-${index}`} src={getMediaFileUrl(image?.mediaFile)} alt={`Gallery itesm clone ${index}`} className="w-full h-full object-cover" />
                    </div>
                })}
            </div>
        </div>
    );
};

export default Gallery;
